import { createApp } from 'vue'
import App from './App.vue'
import { TUIComponents, TUICore } from './TUIKit';



const SDKAppID = 1600022464; 
const userID = 'online_cs';
// const userID = GetQueryString('im_user_id');


const TUIKit = TUICore.init({
  SDKAppID,
});

TUIKit.use(TUIComponents);

TUIKit.login({
    userID: userID,
    userSig: 'eJwtjM0KgkAURt-lbgu5M*iYQotICEMwSY3cRDhj3H5sUgkzevdEXX7nHL4vxMHeeKsKXOAGwnzYJFXZUEEDfpZ3KtUprydZy9tZa5LgMoGInJvCHI1qNVUKXME5LnqFI27o0UNmozC5Y7EprunSnzttuL6yjnXeykq6XXDM7M0h9ItX3upZnHy8wkq3Mg2jyF-C7w9YGzMN',
    // userSig: GetQueryString('im_sign'),
});

createApp(App).use(TUIKit).mount('#app')




export function GetQueryString(name:string) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  let context = "";
  if (r != null){
    context = decodeURIComponent(r[2]);
  }
  r = null;
  return context == null || context == "" || context == "undefined" ? "" : context;
}
