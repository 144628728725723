
import { defineComponent, watchEffect, reactive, toRefs } from 'vue';
import { isUrl, JSONToObject } from '../utils/utils';
import constant from '../../constant';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const VuexStore = ((window as any)?.TUIKitTUICore?.isOfficial && useStore && useStore()) || {};
    const data = reactive({
      data: {} as any,
      extension: {},
      isCustom: '',
      constant: constant,
    });
    watchEffect(() => {
      data.data = props.data;
      const {
        message: { payload },
      } = props.data;
      data.isCustom = payload.data || ' ';
      data.isCustom = JSONToObject(payload.data);
      if (payload.data === constant.typeService) {
        data.extension = JSONToObject(payload.extension);
      }
    });
    const openLink = (url: any) => {
      window.open(url);
    };
    const handleCallMessageIcon = () => {
      const callType = JSON.parse(JSON.parse(data?.data?.message?.payload?.data)?.data)?.call_type;
      let className = '';
      switch (callType) {
        case 1:
          className = 'icon-call-voice';
          break;
        case 2:
          className = 'icon-call-video';
          break;
        default:
          break;
      }
      return className;
    };

    const handleCallAgain = async () => {
      const callType = JSON.parse(JSON.parse(props?.data?.message?.payload?.data)?.data)?.call_type;
      switch (data?.data?.message?.conversationType) {
        case (window as any).TUIKitTUICore.TIM.TYPES.CONV_C2C:
          // eslint-disable-next-line no-case-declarations
          const { flow, to, from } = data?.data?.message;
          if (to === from) break;
          try {
            await (window as any)?.TUIKitTUICore?.TUIServer?.TUICallKit?.call({
              userID: flow === 'out' ? to : from,
              type: callType,
            });
            (window as any)?.TUIKitTUICore?.isOfficial && VuexStore?.commit && VuexStore?.commit('handleTask', 6);
          } catch (error) {
            console.warn(error);
          }
          break;
        case (window as any).TUIKitTUICore.TIM.TYPES.CONV_GROUP:
          break;
        default:
          break;
      }
    };

    const showCustomMsg = () => {
      const { isCustom }: any = data;
      if ('action' in isCustom) {
        if (isCustom['action'] == 'sticker') {
          return '<img class="sticker" src="'+ ( process.env.VUE_APP_IMG_DOMAIN + isCustom['data']['url']) +'"/>';
        } else if (isCustom['action'] == 'bet') {
          if (('revoke' in isCustom['data']) && isCustom['data']['revoke'] == 1) {
            return '撤销投注 <div class="bet-icon"> 注</div>';
          }
          let content:string[] = [];
          const betList = isCustom['data']['bet_info'];
          for(let i = 0; i < betList.length; i++) {
            switch(betList[i]['betType']){
              case 1:
                content.push('庄' + betList[i]['betAmount']);
                break;
              case 2:
                content.push('闲' + betList[i]['betAmount']);
                break;
              case 3:
                content.push('和' + betList[i]['betAmount']);
                break;
              case 4:
                content.push('庄对' + betList[i]['betAmount']);
                break;
              case 5:
                content.push('闲对' + betList[i]['betAmount']);
                break;
              case 6:
                content.push('幸运六' + betList[i]['betAmount']);
                break;
            }
          }
          return content.join(' ') + ' <div class="bet-icon"> 注</div>';
        }
      }
      return '';
    };


    return {
      ...toRefs(data),
      isUrl,
      openLink,
      handleCallMessageIcon,
      handleCallAgain,
      showCustomMsg
    };
  },
});
